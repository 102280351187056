import { useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query'
import { 
  message, 
  Typography, 
  Row, 
  Col, 
  Button, 
  Input,
  Form,
  Spin,
  Tooltip
} from "antd";
import { CopyOutlined } from '@ant-design/icons';
import { queryClient } from "./index.js";
import { getList, postListItem } from './api.js'
import { handleReset } from './helpers.js';
import {
  btnStyle,
  colInnerStyle,
  colLeftStyle,
  colMiddleStyle,
  copyBtnStyle,
  footerLeftStyle,
  inputStyle,
  level3Style,
  level2Style,
  rowStyle,
  createItemStyle,
  spinStyle
} from './styles.js'
import { useForm } from "antd/es/form/Form.js";


const unsecuredCopyToClipboard = (text) => { 
  const textArea = document.createElement("textarea"); 
  textArea.value=text; 
  document.body.appendChild(textArea); 
  textArea.focus();
  textArea.select(); 
  try{document.execCommand('copy')}
  catch(err){console.error('Unable to copy to clipboard',err)}
  document.body.removeChild(textArea)};
/**
 * Copies the text passed as param to the system clipboard
 * Check if using HTTPS and navigator.clipboard is available
 * Then uses standard clipboard API, otherwise uses fallback
*/
const copyToClipboard = (content) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else {
    unsecuredCopyToClipboard(content);
  }
};

const { Title } = Typography;

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = useForm()

  const { isLoading, data = [] } = useQuery({
    queryKey: ['getList'],
    refetchOnWindowFocus: false,
    queryFn: getList
  })

  const mutation = useMutation({
    mutationFn: postListItem,
    onSuccess: (_, name) => {
      const isNew = !data.some(item => item.user_name === name)
      const content = <>API <strong>{name}</strong> a fost {isNew ? 'creat': 'regenerat'}!</>
      messageApi.open({
        type: 'success',
        content,
      })
      queryClient.invalidateQueries({ queryKey: ['getList'] })
      isNew && form.setFieldValue('name', '')
    },
    onError: (error) => console.log('error', error)
  })

  
  const handleCopy = (name, key) => {
    const content = <>API <strong>{name}</strong> a fost copiat!</>
    copyToClipboard(key);
    return messageApi.open({
      type: 'success',
      content
    })
  }
  
  const handleCreate = () => {
    const name = form.getFieldValue('name') ?? ''
    form.validateFields(['name'])
    name.length && mutation.mutate(name)
  }


  useEffect(() => {
    if(data.error) {
      message.error(data.error)
    }
  }, [data])

  const renderContent = () => {
    if (data.error) return <Button onClick={handleReset}>Login</Button>
    return <>
      {data.map(({ user_name, key }) => (
        <Row key={user_name} style={rowStyle} align='middle'>
          <Col span={5} style={colLeftStyle}>{user_name}</Col>
          <Col span={12} style={colMiddleStyle}>
          <Tooltip title={key}><div style={colInnerStyle}>{key}</div></Tooltip>
            {contextHolder}
            <Button 
              icon={<CopyOutlined />}
              onClick={() => handleCopy(user_name, key)}
              style={copyBtnStyle}
            />
          </Col>
          <Col span={7}>
            <Button 
              style={btnStyle}
              onClick={() => mutation.mutate(user_name)}
            >Regenereaza</Button>
          </Col>
        </Row>
      ))}
      <Row style={createItemStyle}>
        <Col span={17} style={footerLeftStyle}>
          <Form form={form}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Adugati numele',
                },
              ]}
            >
              <Input placeholder="Ex: Premier Energy" size='large' style={inputStyle} /> 
            </Form.Item> 
          </Form>
        </Col>
        <Col span={7}>
          <Button 
            size='large'
            style={btnStyle} 
            type="primary"
            onClick={handleCreate}
          >Creaza</Button>
        </Col>
      </Row>
    </>
  }
  return (
    <>
      <Title level={3} style={level3Style}>EMISCON</Title>
      <Title level={2} style={level2Style}>Furniori si managementul cheilor API</Title>
      {isLoading ? 
        <Spin style={spinStyle} size='large' /> : 
        renderContent()}
    </>
  );
}

export default App;
