const requestHeaders = (value) => {
  return {'Authorization': `Bearer ${value}`}
}

// const rootUrl = process.env.REACT_APP_API_URL

let key = sessionStorage.getItem('auth')
let login = sessionStorage.getItem('login')

export const getList = async () => {
  if(!key) {
    key = prompt("Enter key")
    sessionStorage.setItem('auth', key)
  }
  return await fetch(`/admin/key/list`, {
    method: 'GET',
    headers: requestHeaders(key),
  }).then((res) => res.json()
  ).catch((error) => console.log(error))
}

export const getLogs = async () => {
  return await fetch(`/api/tasks?offset=5000`, {
    method: 'GET',
    headers: {'Authorization': `Basic ${login}`},
  }).then((res) => res.json()
  ).catch((error) => console.log(error))
}

export const postListItem = async (name = '') => {
  return await fetch(`/admin/key/upsert?user_name=${name}`, {
    method: 'POST',
    headers: requestHeaders(key),
  },
)}

export const uploadFile = async ({file, file_type} = {}) => {
  const formData = new FormData()
  formData.append('file', file)
  return await fetch(`/api/upload?mode=${file_type}`, {
    method: 'POST',
    headers: {'Authorization': `Basic ${login}`},
    body: formData,
  },
)}

export const getUploadCount = async () => {
  return await fetch(`/api/queue_length`, {
    method: 'GET',
    headers: {'Authorization': `Basic ${login}`},
  }).then((res) => res.json())
    .catch((error) => console.log(error))}