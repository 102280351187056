import { Form } from "antd";
import styled from "styled-components";

export const level2Style = {
  marginTop: 10,
  marginBottom: 30
}

export const level3Style = {
  marginTop: 0,
  paddingTop: 20
}

export const rowStyle = {
  width: '100%', 
  fontSize: 20, 
  marginBottom: 10,
  paddingBottom: 10,
  borderBottom: '1px solid rgb(243 243 243)',
}

export const colLeftStyle = {
  paddingRight: 10, 
  fontWeight: 'bold',
  fontSize: 16,
  wordBreak: 'break-word'
}

export const colMiddleStyle = {
  display: 'flex', 
  alignItems: 'center'
}

export const colInnerStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingRight: 10,
}

export const copyBtnStyle = {
  marginLeft: 'auto',
  minWidth: 32,
  marginRight: 10
}

export const btnStyle = {
  width: '100%'
}

export const inputStyle = {
  marginRight: 10
}

export const footerLeftStyle = {
  paddingRight: 10
}

export const createItemStyle = {
  background: 'white',
  width: '100%',
  position: 'sticky',
  bottom: 0
}

export const spinStyle = {
  width: '100%',
  padding: '50px 0 80px'
}

export const FormItem = styled(Form.Item)`
max-width: 50%;
  .ant-row {
    display: block;
  }
`