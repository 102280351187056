import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UploadPage from './UploadPage';
import './styles.js';
import './global.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/upload",
    element: <UploadPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

export const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </QueryClientProvider>
);
