import { useState } from 'react';
import { Typography, Select, Form, message, Divider } from "antd";
import { useForm } from "antd/es/form/Form.js";
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Spin } from 'antd';
import { useQuery, useMutation } from '@tanstack/react-query'
import { JsonView } from 'react-json-view-lite';
import { uploadFile, getLogs, getUploadCount } from './api.js'
import 'react-json-view-lite/dist/index.css';
import Auth from './Auth.js'
import {
  level3Style,
  level2Style,
  FormItem,
  spinStyle
} from './styles.js'

const { Title, Text } = Typography;

const optionsList = ['delete_bills_excel', 'delete_bills_json', 'bills_json', 'meters_json', 'energy_carrier_fields_json', 'tariffs_json', 'energy_carriers_json', 'vendors_json', 'bills_excel', 'meters_excel', 'energy_carrier_fields_excel', 'tariffs_excel', 'energy_carriers_excel', 'vendors_excel'];

const createOptions = (arr) => arr.map(item => ({ label:item, value:item })) 

function UploadPage() {

  const [form] = useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const { data: logData = {}, isLoading: isLoadingLogData = false, refetch: refetchLogs } = useQuery({
    queryKey: ['getLogs'],
    queryFn: getLogs,
    enabled: isUploaded
  })

  const { data: uploadCountData = '-' } = useQuery({
    queryKey: ['getUploadCount'],
    queryFn: getUploadCount,
  })

  const mutation = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      setFileList([]);
      message.success('upload successfully.');
      setUploading(false);
      setIsUploaded(true)
    },
    onError: (error) => {
      message.error(error.message ?? 'error')
      setUploading(false);
    }
  })

  const handleUpload = () => {
    const { file, file_type } = form.getFieldsValue()
    mutation.mutate({
      file: file.file,
      file_type
    })
    setUploading(true);
  };

  const handleRefreshLogs = () => refetchLogs()

  const props = {
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const renderContent = () => {
    if(!sessionStorage.getItem('login')) return <Auth />
    return <> 
      <Form form={form}>
        <FormItem name='file_type' label='Alegeti tipul fişierului pentru importare' labelAlign='left'>
          <Select 
            options={createOptions(optionsList)} 
            placeholder='Alegeti tipul'
          />
        </FormItem>
        <FormItem name='file' label='Importeaza fişierul' labelAlign='left'>
          <Upload {...props} >
            <Button disabled={fileList.length} icon={<UploadOutlined />}>Alegeti fişierul</Button>
          </Upload>
        </FormItem>
        <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{marginTop: 16}}
          >
            {uploading ? 'Uploading' : 'Importeaza'}
          </Button>
      </Form>
      <Divider />
      <Text style={{marginBottom: 20, display: 'block'}}>Facturi la importare în rînd: {Number(uploadCountData)}</Text>
      <Title level={5}>Rezultat importare <Button onClick={handleRefreshLogs}>Refresh</Button></Title>
      {isLoadingLogData ? <Spin style={spinStyle} size='large' /> : <JsonView data={logData} />}
    </>
  }
    
  return (
    <>
      <Title level={3} style={level3Style}>EMISCON</Title>
      <Title level={2} style={level2Style}>Importarea datelor în EMIS prin intermediul fişierelor</Title>
      {renderContent()}
    </>
  )
}

export default UploadPage